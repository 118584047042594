import styled from "styled-components";
import { Navigate } from "react-router-dom";
import {DeviceQueries, LoginWidget, useLogin} from "@spotswap/shared";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { authLogin, authLogout } from "src/redux/auth";
import Logo from "@spotswap/shared/Logo";
import {links} from "../../config.ts";

const Root = styled.div`
    background-color: white;

    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column;
    
    // place-content: center;
    // place-items: center;

    gap: 96px;

    @media ${DeviceQueries.mobile} {
        gap: 32px;
        padding: 0;
    }

    > header {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 72px;
        padding: 10px 32px 0 32px;

        @media ${DeviceQueries.mobile} {
            display: none;
        }
    }

    > main {
        display: flex;
        flex-flow: column;
        gap: 24px;
        align-items: center;
        
        padding-bottom: 10px;

        img {
            @media ${DeviceQueries.tablet} {
                display: none;
            }
            @media ${DeviceQueries.desktop} {
                display: none;
            }
        }
        
        @media ${DeviceQueries.mobile} {
            padding-top: 10px;
        }
    }
`;

const LoginPage = () => {
  const dispatch = useAppDispatch();

  const {
    config,
    redirectUri,
    realm,
    scope,
  } = useAppSelector((x) => x.environment);

  const {
    token,
  } = useAppSelector((x) => x.auth);

  const {
    from,
    onLogin,
  } = useLogin({
    config,
    redirectUri,
    realm,
    scope,
    doLogout: () => dispatch(authLogout()),
    doLogin: (accessToken: string) => dispatch(authLogin(accessToken)),
  });

  if (token) {
    return <Navigate to={from} />;
  }

  return (
    <Root>
      <header>
        <Logo variant="spotswap-black" />
      </header>
      <main>
        <Logo variant="spotswap-logo" />
        <LoginWidget
          initialValues={{
            email: "evgeny@spotswap.com",
            password: "N@z@r0v!",
          }}
          onLogin={onLogin}
          resetPasswordUri={links.passwordReset}
        />
      </main>
    </Root>
  );
};

export default LoginPage;
