export const links = {
  login: "/login",
  logout: "/logout",
  signup: "/signup",
  passwordReset: "/password-reset",
  home: "/",
  account: {
    root: "/account",
    profile: "/account/profile",
    security: "/account/security",
  },
  waitlists: "/waitlists",
  waitlist: {
    new: "/waitlists/new",
    item: (id: string) => `/waitlists/${id}`,
    fulfilments: (id: string) => `/waitlists/${id}/fulfilments`,
  },
  trades: {
    root: "/trades",
    history: "/trades/history",
    payout: "/trades/payout",
  },
  external: {
    support: "mailto:help@spotswap.com",
    suggest: "mailto:hello@spotswap.com",
    terms: "https://spotswap.com/terms?utm_source=app",
    privacy: "https://spotswap.com/privacy?utm_source=app",
  },
};

export const texts = {
  external: <{ [P in keyof typeof links.external]: string }>{
    support: "help@spotswap.com",
    terms: "Terms of Service",
    privacy: "Privacy Policy",
  },
  copyright: "© SpotSwap 2023",
};

const config = {
  links,
  texts,
};

export default config;
