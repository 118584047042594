import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

const TokenGuard = () => {

  const location = useLocation();

  const token = useAppSelector((x) => x.auth.token);

  return token
    ? (<Outlet />)
    : (<Navigate to="/login" state={location} />);
};

export default TokenGuard;
