import { graphql } from "src/api/gql";

export const UserContextQueryNode = graphql(`
    query UserContext {
        my {
            profile {
                email {
                    formatted
                }
                displayName
                avatar {
                    url
                }
            }
            offers (filter: {status: pending}) {
                totalCount {
                    formatted
                    value
                }
            }
        }
    }
`);
