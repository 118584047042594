import React, { Suspense } from "react";
import {createBrowserRouter, LoaderFunction, redirect, RouterProvider} from "react-router-dom";
import TokenGuard from "./TokenGuard";
import SessionGuard from "../../pages/Login/SessionGuard";
import LoginPage from "src/pages/Login";
import LogoutPage from "src/pages/Logout";
import PasswordResetPage from "src/pages/PasswordReset";
import { Layout } from "../../components/Layout";
import userContextLoader from "./userContextLoader.ts";
import SomethingWentWrongPage from "../../pages/SomethingWentWrong";
import tokenLoader from "./tokenLoader.ts";

const HomeRoot = React.lazy(() => import("src/pages/Home"));
const Signup = React.lazy(() => import("src/pages/Signup"));
const Onboarding = React.lazy(() => import("src/pages/Onboarding"));

const AccountRoot = React.lazy(() => import("src/pages/Account"));
const AccountProfile = React.lazy(() => import("src/pages/Account/Profile"));
const AccountSecurity = React.lazy(() => import("src/pages/Account/Security"));

const Waitlists = React.lazy(() => import("src/pages/Waitlists"));
const Waitlist = React.lazy(() => import("src/pages/Waitlist/Waitlist"));

const TradesRoot = React.lazy(() => import("src/pages/Trades"));
const TradesHistory = React.lazy(() => import("src/pages/Trades/History"));
const TradesPayout = React.lazy(() => import("src/pages/Trades/Payout"));

export const NULL: LoaderFunction = () => {
  return null;
};

const AppRouter = () => {
  const login = (<LoginPage />);
  const logout = (<LogoutPage />);
  const passwordReset = (<PasswordResetPage />);
  const layout = (<Layout />);
  const tokenGuard = (<TokenGuard />);
  const sessionGuard = (<SessionGuard />);

  const suspended = (children: React.ReactNode, skeleton?: React.ReactNode) => (
    <Suspense fallback={skeleton}>
      {children}
    </Suspense>
  );

  const router = createBrowserRouter([
    {
      path: "login",
      element: login,
    },
    {
      path: "logout",
      element: logout,
    },
    {
      path: "signup",
      element: suspended(<Signup />),
    },
    {
      path: "onboarding",
      element: suspended(<Onboarding />),
      loader: tokenLoader(userContextLoader(NULL)),
    },
    {
      path: "password-reset",
      element: passwordReset,
    },
    {
      element: sessionGuard,
      children: [{
        element: tokenGuard,
        children: [{
          element: layout,
          errorElement: (<SomethingWentWrongPage />),
          loader: tokenLoader(userContextLoader(NULL)),
          children: [ {
            path: "/",
            element: suspended(<HomeRoot/>),
          },
          {
            path: "account",
            element: suspended(<AccountRoot />),
            children: [ {
              index: true,
              loader: () => redirect("/account/profile"),
              handle: {
                crumb: () => {
                  return { selectedIndex: 0 };
                },
              },
            }, {
              path: "profile",
              element: suspended(<AccountProfile />),
              handle: {
                crumb: () => {
                  return { selectedIndex: 0 };
                },
              },
            },
            {
              path: "security",
              element: suspended(<AccountSecurity />),
              handle: {
                crumb: () => {
                  return { selectedIndex: 1 };
                },
              },
            } ],
          },
          {
            path: "/waitlists",
            element: suspended(<Waitlists/>),
          },
          {
            path: "/waitlists/:waitlistID",
            element: suspended(<Waitlist/>),
          },
          {
            path: "trades",
            element: suspended(<TradesRoot />),
            children: [ {
              index: true,
              loader: () => redirect("/trades/history"),
              handle: {
                crumb: () => {
                  return { selectedIndex: 0 };
                },
              },
            }, {
              path: "history",
              element: suspended(<TradesHistory />),
              handle: {
                crumb: () => {
                  return { selectedIndex: 0 };
                },
              },
            },
            {
              path: "payout",
              element: suspended(<TradesPayout />),
              handle: {
                crumb: () => {
                  return { selectedIndex: 1 };
                },
              },
            } ],
          },
          ],
        }],
      }],
    },
  ]);

  return (<RouterProvider router={router} />);
};

export default AppRouter;
