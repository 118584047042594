import { useCallback, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { SidebarWidget } from "@spotswap/shared";
import Root from "@spotswap/shared/Layout";
import SidebarMenu from "src/components/SidebarMenu";
import { useAppSelector } from "src/redux/hooks";
import { links } from "src/config";

export const Layout = () => {
  const {
    user,
  } = useAppSelector((x) => x.auth);

  if (!user) {
    throw Error("[Layout] A component was used prior the admin context load");
  }

  const navigate = useNavigate();

  const handleProfile = useCallback(() => navigate(links.account.profile), [navigate]);

  const handleLogout = useCallback(() => navigate(links.logout), [navigate]);

  const sidebar = useMemo(() => (
    <SidebarWidget
      theme="global"
      userAvatarUrl={user?.avatarUrl}
      userLoginEmail={user?.email}
      userDisplayName={user?.displayName}
      onProfile={handleProfile}
      onLogout={handleLogout}>
      <SidebarMenu />
    </SidebarWidget>
  ), [ user?.avatarUrl, user?.email, user?.displayName, handleProfile, handleLogout ]);

  const content = useMemo(() => (
    <Outlet />
  ), []);

  return (
    <Root
      theme="global"
      sidebar={sidebar}
      content={content}
    />
  );
};

export default Layout;
