import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect } from "react";
import { WebAuth } from "auth0-js";
import { LoadingWidget } from "@spotswap/shared";
import { authLogin, authLogout } from "../../redux/auth";
import { Outlet } from "react-router-dom";

const SessionGuard = () => {
  const {
    config,
    redirectUri,
    scope,
  } = useAppSelector((x) => x.environment);

  const {
    checked,
  } = useAppSelector((x) => x.auth);

  const dispatch = useAppDispatch();

  if (!config) {
    throw Error("[SessionGuard] Session guard was used prior the config load");
  }

  const auth = new WebAuth({
    domain: config.auth.domain,
    clientID: config.auth.clientID,
    audience: config.auth.audience,
    redirectUri,
    scope,
  });

  useEffect(() => {
    if (!checked) {
      auth.checkSession({
        responseType: "token",
      }, (e, x) => {
        if (e || !x.accessToken) {
          dispatch(authLogout());
          if (e) {
            console.warn("checkSession", e);
          }
        } else {
          dispatch(authLogin(x.accessToken));
        }
      });
    }
  }, [checked]);

  return !checked ? (<LoadingWidget />) : (<Outlet />);
};

export default SessionGuard;
