import { LoaderFunction } from "react-router-dom";
import { store } from "src/redux/store";
import { authInit } from "src/redux/auth";
import { requestClient } from "src/environment";
import { UserContextQueryNode } from "./UserContext";
import {useQueryClient} from "@tanstack/react-query";

export const UserContextLoader = (wrapped: LoaderFunction): LoaderFunction => {
  const queryClient = useQueryClient();

  return async (args) => {
    const {
      auth,
    } = store.getState();

    console.debug("[userContextLoader] Checking if partner context is present");

    if (!auth.user) {
      console.debug("[userContextLoader] User context is not present, attempting to reacquire");

      await queryClient.fetchQuery({
        queryKey: ["UserContextQuery"],
        queryFn: async () => {
          const response = await requestClient.request({
            document: UserContextQueryNode,
            variables: {},
            signal: args.request.signal,
          });

          const {
            my,
          } = response;

          store.dispatch(authInit({
            global: {
              displayName: my.profile.displayName,
            },
            user: {
              email: my.profile.email.formatted,
              displayName: my.profile.displayName,
              avatarUrl: my.profile.avatar?.url,
              activeOffers: my.offers.totalCount,
            },
          }));
        },
      });
        

    }

    console.debug("[userContextLoader] User context is present, continuing");
    return wrapped(args);
  };
};

export default UserContextLoader;
